import React from 'react';
import { ThemeProvider, GlobalStyle, SnackbarProvider } from '@stashinvest/ui';
import Dashboard from './Dashboard';
import AuthProvider from './providers/AuthProvider';

class App extends React.Component {

  render() {
    return (
      <div className="App">
        <AuthProvider>
          <ThemeProvider>
            <SnackbarProvider >
              <div>
                <GlobalStyle withFonts />
                <Dashboard />
              </div>
            </SnackbarProvider>
          </ThemeProvider>
        </AuthProvider>
      </div>
    );
  }
}

export default App;
