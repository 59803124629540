import React, { useState } from 'react';
import { ListView, PrimaryButton, Tile, Collapse, SmartLink } from '@stashinvest/ui';

const ContractCategoryTile = (props) => {
  const { category, contracts } = props;
  const [ isOpen, setIsOpen ] = useState(true);

  const contractViews = contracts.map((contract) => {
    return (
      <Collapse key={contract.id} isOpened={isOpen}>
        <ListView
        title={<strong>{contract.endpoint}</strong>}
        subtitle={contract.description}
        value={<PrimaryButton to={`/contract/${contract.id}`} as={SmartLink}>Edit</PrimaryButton>}/>
      </Collapse>);
  });
  return (<Tile>
    <Tile.Header title={<span onClick={() => { setIsOpen(!isOpen) }}>{category}</span>} />
    <ul>
      {contractViews}
    </ul>
  </Tile>)
}

export default ContractCategoryTile;
