import React, { useEffect, useState }  from 'react';
import { useAuthContext } from '../providers/AuthProvider';
import AceEditor from "react-ace";
import { useParams } from "react-router-dom";
import { useFetch } from "@stashinvest/use-fetch";
import { Layout, Loader, Select, PrimaryButton, QuickActions, InlineFlex, Snackbar, useSnackbarContext } from '@stashinvest/ui';
import EditContractInfo from "../components/contract/EditContractInfo";
import ContractVariantDialog from "../components/contract/ContractVariantDialog";

import "ace-builds/src-noconflict/mode-json";
import "ace-builds/src-noconflict/theme-monokai";

const EditContract = () => {
  const { token } = useAuthContext();
  const { contractId } = useParams();
  const { setSnackbar } = useSnackbarContext();
  const [selectedVariant, setSelectedVariant] = useState("");
  const [isVariantSelectorOpen, setIsVariantSelectorOpen] = useState(false);
  const [contractData, setContractData] = useState({
    id: "",
    endpoint: "",
    method: "GET",
    category: "",
    description: "",
    request: "{}",
    response: "{}",
    variantName: selectedVariant,
    statusCode: 200
  });
  const [selectedAction, setSelectedAction] = useState(0);
  const headers = {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
  };
  const UseEditContractFetch = options => {
    return useFetch({
      path: `/treaty/api/contracts/${contractId}`,
      headers: headers,
      ...options
    });
  };

  const getEditContract = UseEditContractFetch();
  const updateEditContract = UseEditContractFetch({ method: useFetch.PUT });

  useEffect(() => {
    getEditContract.fetch({headers: {...headers, 'treaty-contract-variant-name': selectedVariant}});
  }, [selectedVariant]);

  useEffect(() => {
    if(!updateEditContract.data) {
      return;
    }

    setSnackbar({
      title:"Contract Updated!",
      body:"Your contract was successfully updated",
      backgroundColor:"brookPurple",
      textColor:"white",
      duration: 2000
    });
  }, [updateEditContract.data]);

  useEffect(() => {
    if(!getEditContract.data) {
      return;
    }

    setContractData({
      id: getEditContract.data.contract.id,
      endpoint: getEditContract.data.contract.endpoint,
      method: getEditContract.data.contract.method,
      category: getEditContract.data.contract.category,
      description: getEditContract.data.contract.description,
      request: JSON.stringify(getEditContract.data.contract.request, null, 2),
      response: JSON.stringify(getEditContract.data.contract.response, null, 2),
      variant: getEditContract.data.contract.variant,
      variantName: selectedVariant,
      statusCode: getEditContract.data.contract.statusCode
    });
  }, [getEditContract.data]);

  const updateContract = () => {
    const updateBody = {
      variantName: selectedVariant,
      statusCode: contractData.statusCode,
      contract: {
        endpoint: contractData.endpoint,
        method: contractData.method,
        category: contractData.category,
        description: contractData.description,
        request: JSON.parse(contractData.request),
        response: JSON.parse(contractData.response)
      }
    };
    updateEditContract.fetch({ body: updateBody, headers: {...headers, 'treaty-contract-variant': selectedVariant}})
  };

  const getVariantOptions = () => {
    const options = []
    let i;
    for (i = 0; i < 100; i++) {
      options.push(<option value={i}>{i}</option>)
    }
    return options;
  }

  const getSection = () => {
    switch(selectedAction) {
      case 1:
        return (
        <AceEditor
            mode="json"
            theme="monokai" 
            name="request_editor" 
            width="100%" 
            showPrintMargin={false}
            value={contractData.request} 
            onChange={(value, event) => setContractData({...contractData, request: value})}/>
            );
      case 2:
        return (
          <AceEditor 
            mode="json" 
            theme="monokai" 
            name="response_editor" 
            width="100%" 
            showPrintMargin={false}
            value={contractData.response} 
            onChange={(value, event) => setContractData({...contractData, response: value})}/>
            );
      default:
        return (<EditContractInfo contract={contractData} onContractUpdate={(updatedContract) => setContractData(updatedContract)} onVariantClick={() => setIsVariantSelectorOpen(true)} />);
    }
  };

  return (
    <div>
      <ContractVariantDialog 
        isOpen={isVariantSelectorOpen} 
        contractId={contractId}
        previousVariant={selectedVariant}
        onConfirm={(variant) => {
          console.log(variant);
          setSelectedVariant(variant);
          setIsVariantSelectorOpen(false);
        }} 
        onCancel={() => setIsVariantSelectorOpen(false)} />
      <Layout.PageWrap>
        <Layout.PurpleBackground flapHeight="140px"/>
          <Layout.ContentWrap wide>
          {updateEditContract.loading ? <PrimaryButton type="button" size="large" mb="xs" loading>Save</PrimaryButton> : <PrimaryButton type="button" size="large" mb="xs" onClick={() => updateContract()}>Save</PrimaryButton>}
          <QuickActions
            actions={[
              { label: 'Info', onClick: () => { setSelectedAction(0) } },
              { label: 'Request', onClick: () => { setSelectedAction(1) } },
              { label: 'Response', onClick: () => { setSelectedAction(2) } }
            ]}
          />
          {getEditContract.loading || getEditContract.error || !getEditContract.data ? <Loader p="xs" /> : getSection()}
          </Layout.ContentWrap>
      </Layout.PageWrap>
    </div>
  )
}

export default EditContract;