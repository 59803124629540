import React, { useEffect } from 'react';
import { Router } from 'react-router-dom';
import history from './services/history';
import { useAuthContext } from './providers/AuthProvider';
import Routes from './routes';
import TopBar from './components/core/TopBar'
import './Dashboard.css';

const Dashboard = () => {
  const { onLogin } = useAuthContext();

  useEffect(() => {
    const regex = /\?code=(?<token>.*)&state=(?<state>.*)/
    const match = window.location.search.match(regex)
    if (match && match.groups.token && match.groups.state) {
      const { token, state } = match.groups;
      getToken(token, state);
    }
  });

  const getToken = async (code, state) => {
    const tokenRequest = await fetch(`/api/v1/auth/okta/callback?code=${code}&state=${state}`)
    const { token } = await tokenRequest.json();
    onLogin(token);

    // clear the url.
    history.push("/");
  }

  return (
    <div className="dashboard">
      <Router history={history}>
        <TopBar/>
        <Routes />
      </Router>
    </div>
  );
}

export default Dashboard;