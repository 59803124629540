import React from 'react';
import { useAuthContext } from '../../providers/AuthProvider';
import { Formik, Form } from "formik";
import { Dialog, FormWrapper, FormikTextInput, PrimaryButton, Loader } from '@stashinvest/ui';
import { useFetch } from "@stashinvest/use-fetch";

const CreateDeeplinkDialog = (props) => {
  const { onComplete, onCancel } = props;
  const { token } = useAuthContext();
  const { fetch, loading } = useFetch({
    path: "/treaty/api/deeplinks",
    method: useFetch.POST,
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    }
  });

  const createDeeplink = async (values) => {
    const requestData = {
      deeplink: values.deeplink,
      name: values.name,
      versionImplementedAndroid: values.androidAdd,
      versionDeprecatedAndroid: values.androidDeprecated,
      versionImplementedIos: values.iosAdded,
      versionDeprecatedIos: values.iosDeprecated,
      versionDeprecatedWeb: values.webAdded,
      versionImplementedWeb: values.webDeprecated
    }
    const result = await fetch({ body: requestData});
    return result.contract;
  }

  const getForm = () => {
    return (
      <FormWrapper>
      <Form>
        <FormikTextInput name="deeplink" label="Deeplink (https://app.stashinvest.com/deeplink)" />
        <FormikTextInput name="name" label="Name" />
        <FormikTextInput name="androidAdd" label="Android Version Added (Android 1.x.x.x)" />
        <FormikTextInput name="androidDeprecated" label="Adroid Version Deprecated (Android 1.x.x.x)" />
        <FormikTextInput name="iosAdded" label="iOS version Added (iOS 2.x.x)" />
        <FormikTextInput name="iosDeprecated" label="iOS Version Deprecated (iOS 2.x.x)" />
        <FormikTextInput name="webAdded" label="Web Version Added (Web 4.x.x)" />
        <FormikTextInput name="webDeprecated" label="Web Version Deprecated (Web 4.x.x)" />
        <PrimaryButton type="submit">Submit</PrimaryButton>
      </Form>
    </FormWrapper>
    );
  };

  return (
    <Dialog
        open={props.isOpen}
        prompt={
          <Formik
            initialValues={{ endpoint: "", method: "GET", category: "", description: "" }}
            onSubmit={(values, actions) => {
              createDeeplink(values).then((contract) => {
                actions.setSubmitting(false);
                onComplete(contract);
              });
            }}>
            {props => (loading ? <Loader p="xs" /> : getForm())}
          </Formik>
        }
        heading="Create a new deeplink"
        hideConfirmButton={true}
        cancel={() => onCancel(false)}
        confirmText="Create"
        cancelText="Cancel"/>
  )
}

export default CreateDeeplinkDialog;
