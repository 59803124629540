import React from 'react';
import { useAuthContext } from '../../providers/AuthProvider';
import { Link, NavLink } from 'react-router-dom';
import styled, { css } from 'styled-components/macro';
import { Flex, Layout } from '@stashinvest/ui';

const NavigationBar = styled.header`
	background: rgba(0, 0, 0, 0.3);
	color: #fff;
	box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
	margin-bottom: 1.5em;
	width: 100%;
	position: fixed;
	top: 0;
	z-index: 11;

	&:before {
		content: '';
		position: absolute;
		background: #3d1a85;
		width: 100%;
		height: 100%;
		opacity: 1;
		transition: opacity 0.35s;
	}

	&.transparent {
		box-shadow: none;
	}

	&.transparent:before {
		opacity: 0;
	}
`;

const StashLogo = styled(Link)`
	text-indent: -9999px;
	background-position: -3px 50%;
	background-size: contain;
	width: 90px;
	min-width: 60px;
	margin-right: 2%;
	z-index: 1;
	display: block;

	${({ theme }) => theme.mediaQueries.tablet} {
		margin-right: 5%;
		width: 108px;
	}
`;

const TopNav = styled.nav`
	display: flex;
	flex-grow: 1;
	align-items: center;
`;

const TopNavItem = styled.ul`
	position: relative;
	display: flex;
	flex-grow: 1;
	z-index: 1;
	margin-right: 0.5rem;
	visibility: ${({ hidden }) => hidden && 'hidden'};
`;

const TopNavLi = styled.li`
	display: none;

	&:last-child { margin-right: 0; }

	${({ theme }) => theme.mediaQueries.tablet} {
		display: block;
		margin-right: 1.65rem;
	}
}
`;

const navItemMixin = css`
	text-decoration: none;
	color: rgba(255, 255, 255, 0.7);
	font-size: 1rem;
	text-rendering: optimizeLegibility;
`;

const TopNavItemLink = styled(NavLink)`
	${navItemMixin}
	display: flex;
	box-sizing: border-box;
	align-items: center;
	min-height: 4rem;
	border-bottom: 2px solid transparent;
	transition: color 0.2s, border-bottom-color 0.2s;

	&.active {
		border-bottom-color: #fff;
	}

	&:before {
		content: '';
		background-size: contain;
		background-repeat: no-repeat;
		min-width: 1.2em;
		height: 1.2em;
		margin-right: 0.5em;
		opacity: 0.7;
		transition: opacity 0.2s;
		background-image: url(${props => props.icon || ''});
	}

	&:hover,
	&.active {
		color: rgba(255, 255, 255, 1);

		&:before {
			opacity: 1;
		}
	}
`;

const URL_MATCHES = {
	home: ['/', '/contract', '/contracts'],
	deeplinks: [
		'/deeplinks'
	]
};

const TopBar = () => {
	const { token } = useAuthContext();

	if(!token) {
		return (<div></div>)
	}

	return (
		<NavigationBar>
			<Layout.ContentWrap wide>
				<Flex justifyContent="space-between" align-items="center">
					<TopNav>
						<TopNavItem>
							<TopNavLi>
								<TopNavItemLink
									exact
									to={`/`}
									isActive={(match, location) =>
										URL_MATCHES['home'].includes(location.pathname)
									}>
									Contracts
								</TopNavItemLink>
							</TopNavLi>
							<TopNavLi>
								<TopNavItemLink
									to="/deeplinks"
									isActive={(match, location) =>
										URL_MATCHES['deeplinks'].includes(location.pathname)
									}>
									Deeplinks
								</TopNavItemLink>
							</TopNavLi>
						</TopNavItem>
					</TopNav>
				</Flex>
			</Layout.ContentWrap>
		</NavigationBar>
	);
};

export default TopBar;
