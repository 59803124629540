import React, { useEffect, useState }  from 'react';
import { useAuthContext } from '../providers/AuthProvider';
import { useFetch } from "@stashinvest/use-fetch";
import { Layout, PrimaryButton, Loader, TextInput, useSnackbarContext } from '@stashinvest/ui';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import CreateDeeplinkDialog from '../components/deeplink/CreateDeeplinkDialog';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
});

const Deeplinks = () => {
  const classes = useStyles();
  const { token } = useAuthContext();
  const { setSnackbar } = useSnackbarContext();
  const [deeplinks, setDeeplinks] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [isCreateDialogOpen, setIsCreateDialogOpen] = useState(false);
  
  const { fetch, data, loading, error } = useFetch({
    path: "/treaty/api/deeplinks",
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    }
  });

  useEffect(() => {
    fetch();
  }, [fetch]);

  useEffect(() => {
    if(!data) {
      return;
    }

    setDeeplinks(filterDeeplinks(data.deeplinks));
  }, [data]);

  useEffect(() => {
    if(!data) {
      return;
    }

    const filteredData = filterDeeplinks(data.deeplinks)
    setDeeplinks(filteredData);
  }, [searchTerm]);

  const filterDeeplinks = (data) => {
    return data.filter(deeplink => deeplink.deeplink.toLowerCase().includes(searchTerm));
  };

  const onNewDeeplink = (deeplink) => {
    setIsCreateDialogOpen(false);
    setSnackbar({
      title:"Deeplink Submitted!",
      body:"Your deeplink was successfully sent for approval",
      backgroundColor:"brookPurple",
      textColor:"white",
      duration: 2000
    });
  };

  const getRows = () => {
    return deeplinks.map((deeplink) => (
      <StyledTableRow key={deeplink.deeplink}>
        <StyledTableCell component="th" scope="row">
          {deeplink.name}
        </StyledTableCell>
        <StyledTableCell align="right">{deeplink.deeplink}</StyledTableCell>
        <StyledTableCell align="right">{"todo"}</StyledTableCell>
        <StyledTableCell align="right">{deeplink.versionImplementedAndroid}</StyledTableCell>
        <StyledTableCell align="right">{deeplink.versionDeprecatedAndroid}</StyledTableCell>
        <StyledTableCell align="right">{deeplink.versionImplementedIos}</StyledTableCell>
        <StyledTableCell align="right">{deeplink.versionDeprecatedIos}</StyledTableCell>
        <StyledTableCell align="right">{deeplink.versionDeprecatedWeb}</StyledTableCell>
        <StyledTableCell align="right">{deeplink.versionImplementedWeb}</StyledTableCell>
      </StyledTableRow>
    ))
  };

  return (
    <div>
      <CreateDeeplinkDialog 
        isOpen={isCreateDialogOpen}
        onComplete={(contract) => onNewDeeplink(contract)}
        onCancel={() => setIsCreateDialogOpen(false)}/>
      <Layout.PageWrap>
        <Layout.PurpleBackground flapHeight="120px"/>
        <Layout.ContentWrap wide>
          <PrimaryButton type="button" size="medium" mb="m" onClick={() => setIsCreateDialogOpen(true)}>Create</PrimaryButton>
          <TextInput label="Search" onChange={(event) => setSearchTerm(event.target.value.toLowerCase())} />
          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell>Name</StyledTableCell>
                  <StyledTableCell align="right">Deeplink</StyledTableCell>
                  <StyledTableCell align="right">Parameters</StyledTableCell>
                  <StyledTableCell align="right">Android Added</StyledTableCell>
                  <StyledTableCell align="right">Android Deprecated</StyledTableCell>
                  <StyledTableCell align="right">iOS Added</StyledTableCell>
                  <StyledTableCell align="right">iOS Deprecated</StyledTableCell>
                  <StyledTableCell align="right">Web Added</StyledTableCell>
                  <StyledTableCell align="right">Web Deprecated</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
              { loading ? <Loader /> : getRows() }
              </TableBody>
            </Table>
          </TableContainer>
        </Layout.ContentWrap>
      </Layout.PageWrap>
    </div>
  )
}

export default Deeplinks;