import React, { useEffect, useState }  from 'react';
import { useAuthContext } from '../providers/AuthProvider';
import { useFetch } from "@stashinvest/use-fetch";
import { Layout, PrimaryButton, Loader, TextInput } from '@stashinvest/ui';
import ContractCategoryTile from '../components/contract/ContractCategoryTile';
import CreateContractDialog from '../components/contract/CreateContractDialog';

const Contracts = () => {
  const { token } = useAuthContext();
  const [contractsByCategory, setContractsByCategory] = useState([]);
  const [isCreateDialogOpen, setIsCreateDialogOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const { fetch, data, loading, error } = useFetch({
    path: "/treaty/api/home",
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    }
  });

  const getContractCategories = () => {
    return Object.keys(contractsByCategory).sort().map((key) => {
      return (<ContractCategoryTile 
        key={key} 
        category={key} 
        contracts={contractsByCategory[key]} />);
    });
  };

  useEffect(() => {
    fetch();
  }, [fetch]);

  useEffect(() => {
    if(!data) {
      return;
    }

    const contracts = data.contracts || [];
    const categorySections = getCategorizedContracts(contracts);
    setContractsByCategory(categorySections)
  }, [data]);

  useEffect(() => {
    if(!data) {
      return;
    }

    const contracts = data.contracts || [];
    debugger;
    const categorySections = getCategorizedContracts(contracts);
    setContractsByCategory(categorySections)
  }, [searchTerm]);

  const getCategorizedContracts = (contracts) => {
    const categorySections = contracts.reduce((acc, current) => {
      if(!current.endpoint.toLowerCase().includes(searchTerm) && !current.category.toLowerCase().includes(searchTerm)) {
        return acc;
      }

      let category = 'unknown';
      if(current.category) {
        category = current.category.toLowerCase();
      }

      const next = acc[category] || [];
      next.push(current);
      acc[category] = next;
      return acc;
    }, {});
    return categorySections;
  };

  const onNewContract = (contract) => {
    const newCategorizedContracts = {...contractsByCategory};
    const contracts = newCategorizedContracts[contract.category] || [];
    contracts.push(contract);
    newCategorizedContracts[contract.category] = contracts;

    setContractsByCategory(newCategorizedContracts);
    setIsCreateDialogOpen(false);
  };

  return (
    <div>
      <CreateContractDialog 
        isOpen={isCreateDialogOpen}
        onComplete={(contract) => onNewContract(contract)}
        onCancel={() => setIsCreateDialogOpen(false)}/>
      <Layout.PageWrap>
        <Layout.PurpleBackground flapHeight="120px"/>
        <Layout.ContentWrap wide>
          <PrimaryButton type="button" size="medium" mb="m" onClick={() => setIsCreateDialogOpen(true)}>Create</PrimaryButton>
          <TextInput label="Search" onChange={(event) => setSearchTerm(event.target.value.toLowerCase())} />
          <ul>
          {loading || error || !data ? <Loader p="xs" /> : getContractCategories()}
          </ul>
        </Layout.ContentWrap>
      </Layout.PageWrap>
    </div>
  )
}

export default Contracts;