import React, { useState, useEffect } from 'react';
import { useAuthContext } from '../../providers/AuthProvider';
import { Dialog, FormWrapper, TextInput, Select } from '@stashinvest/ui';
import { useFetch } from "@stashinvest/use-fetch";

const ContractVariantDialog = (props) => {
  const { isOpen, contractId, onConfirm, onCancel } = props;
  const { token } = useAuthContext();
  const [ variant, setVariant ] = useState("");
  const [ variantOptions, setVariantOptions ] = useState([])
  const { fetch, data, loading, error } = useFetch({
    path: `/treaty/api/contracts/${contractId}/variants`,
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    }
  });

  useEffect(() => {
    fetch();
  }, [fetch]);

  useEffect(() => {
    if(!data) {
      return;
    }

    console.log(data);
    setVariantOptions([...data.variants]);
  }, [data]);

  useEffect(() => {
    setVariant(props.previousVariant)
  }, [props]);

  return (
    <Dialog
        open={isOpen}
        prompt={
          <FormWrapper>
            <Select name="variantOptions" label="Existing Variants" value={variant} onChange={(event) => setVariant(event.target.value)}>
              { variantOptions.map((option) => (<option value={option}>{option}</option>)) }
            </Select>
            <TextInput name="variant" label="Variant Name" value={variant} onChange={(event) => setVariant(event.target.value)} />
          </FormWrapper>
        }
        heading="Change Variant"
        confirm={() => onConfirm(variant)}
        cancel={() => onCancel(false)}
        confirmText="Confirm"
        cancelText="Cancel"/>
  )
}

export default ContractVariantDialog;
