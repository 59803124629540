
export const setCookie = (name, value, options) => {

	const expires =
		(options && options.expires) ||
		(options && options['max-age'] && getExpires(options['max-age'])) ||
		(options && options.expiresEpoch && getExpiresEpoch(options.expiresEpoch)) ||
		getExpires(60 * 5);

	document.cookie = `${name}=${value}; expires=${expires}; path=/`;

	return value;
};

export const getCookie = name => {
	function escape(s) {
		// eslint-disable-next-line no-useless-escape
		return s.replace(/([.*+?\^${}()|\[\]\/\\])/g, '\\$1');
	}
	var match = document.cookie.match(
		RegExp('(?:^|;\\s*)' + escape(name) + '=([^;]*)')
	);

	return match ? match[1] : undefined;
};

export const deleteCookie = name => {
	return setCookie(name, '', { 'max-age': -1 });
};

function getExpires(maxAge) {
	// convert a maxAge to an 'expires' date.
	return new Date(Date.now() + maxAge * 1000).toUTCString();
}

function getExpiresEpoch(epoch) {
	// convert a epoch to an 'expires' date.
	const date = new Date(0);
	date.setUTCSeconds(epoch);
	return date.toUTCString();
}
