import React from 'react';

import { Text, Layout, PrimaryButton, theme } from '@stashinvest/ui';

const Login = () => {
  const loginClick = () => {
    window.location.href = `http://${window.location.hostname}:${window.location.port}/api/v1/auth/okta/login`
  }

  return (
    <Layout.PageWrap>
      <Layout.PurpleBackground flapHeight="70px">
        <Layout.ContentWrap>
          <Text.H1 color={theme.colorThemes.dark.label} mb="s">
            Welcome to Treaty
        </Text.H1>
          <Text.H3 color={theme.colorThemes.dark.secondaryLabel} mb="l">
            Please authenticate via okta to login.
        </Text.H3>
        </Layout.ContentWrap>
      </Layout.PurpleBackground>
      <Layout.FadeInContainer>
        <Layout.ContentWrap>
          <PrimaryButton onClick={() => loginClick()} type="button">Login now</PrimaryButton>
        </Layout.ContentWrap>
      </Layout.FadeInContainer>
    </Layout.PageWrap>
  );
}

export default Login;
