
import React, { createContext, useState, useEffect, useMemo, useContext } from "react";
import { ACCESS_TOKEN_COOKIE_NAME } from '../utils/constants';
import { setCookie, getCookie, deleteCookie } from '../utils/cookies';
import jwtDecode from 'jwt-decode';

export const AuthContext = createContext(null);

const initialAuthData = {};

const AuthProvider = (props) => {
  const [authData, setAuthData] = useState(initialAuthData);

  /* The first time the component is rendered, it tries to
   * fetch the auth data from a source, like a cookie or
   * the localStorage.
   */
  useEffect(() => {
    const token = getCookie(ACCESS_TOKEN_COOKIE_NAME);
    if (token) {
      const decodedToken = jwtDecode(token);
      setAuthData({
        user: decodedToken.sub,
        token: token
      });
    }
  }, []);

  const onLogout = () => {
    deleteCookie(ACCESS_TOKEN_COOKIE_NAME);
    setAuthData(initialAuthData);
  }

  const onLogin = token => {
    const decodedToken = jwtDecode(token);
    setCookie(ACCESS_TOKEN_COOKIE_NAME, token, { expiresEpoch: decodedToken.exp })
    setAuthData({
      user: decodedToken.sub,
      token: token
    });
  }

  const authDataValue = useMemo(() => { 
    return {...authData, onLogin, onLogout};
  }, [authData]);

  return <AuthContext.Provider value={authDataValue} {...props} />;
};

export const useAuthContext = () => useContext(AuthContext);

export default AuthProvider;