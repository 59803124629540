import React, { useState, useEffect } from 'react';
import { useAuthContext } from '../../providers/AuthProvider';
import { Formik, Form } from "formik";
import { Dialog, FormWrapper, FormikTextInput, FormikSelect, PrimaryButton, Loader } from '@stashinvest/ui';
import { useFetch } from "@stashinvest/use-fetch";

const CreateContractDialog = (props) => {
  const { onComplete, onCancel } = props;
  const { token } = useAuthContext();
  const { fetch, loading } = useFetch({
    path: "/treaty/api/contracts",
    method: useFetch.POST,
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    }
  });

  const createContract = async (values) => {
    const requestData = {
      variant: 0,
      contract: {
        endpoint: values.endpoint,
        method: values.method,
        category: values.category,
        description: values.description,
        request: {},
        response: {}
      }
    }
    const result = await fetch({ body: requestData});
    return result.contract;
  }

  const getForm = () => {
    return (
      <FormWrapper>
      <Form>
        <FormikTextInput name="endpoint" label="Endpoint (e.g. /static/api/v1/{userId})" />
        <FormikSelect name="method" maxWidth="25rem" label="Request Method" value="GET">
          <option value="GET">GET</option>
          <option value="POST">POST</option>
          <option value="PUT">PUT</option>
          <option value="DELETE">DELETE</option>
        </FormikSelect>
        <FormikTextInput name="category" label="Category" />
        <FormikTextInput name="description" label="Description" />
        <PrimaryButton type="submit">Submit</PrimaryButton>
      </Form>
    </FormWrapper>
    );
  };

  return (
    <Dialog
        open={props.isOpen}
        prompt={
          <Formik
            initialValues={{ endpoint: "", method: "GET", category: "", description: "" }}
            onSubmit={(values, actions) => {
              createContract(values).then((contract) => {
                actions.setSubmitting(false);
                onComplete(contract);
              });
            }}>
            {props => (loading ? <Loader p="xs" /> : getForm())}
          </Formik>
        }
        heading="Create a new endpoint"
        hideConfirmButton={true}
        cancel={() => onCancel(false)}
        confirmText="Create"
        cancelText="Cancel"/>
  )
}

export default CreateContractDialog;
