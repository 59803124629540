import React, { useState, useEffect } from 'react';
import { TextInput, Select, PrimaryButton, InlineFlex } from '@stashinvest/ui';

const CreateContractDialog = (props) => {
  const { onContractUpdate, onVariantClick } = props;

  const onStatusUpdate = (value) => {
    onContractUpdate({
      ...props.contract,
      statusCode: value
    });
  };

  const onEndpointUpdate = (value) => {
    onContractUpdate({
      ...props.contract,
      endpoint: value
    });
  };

  const onMethodUpdate = (value) => {
    onContractUpdate({
      ...props.contract,
      method: value
    });
  };

  const onCategoryUpdate = (value) => {
    onContractUpdate({
      ...props.contract,
      category: value
    });
  };

  const onDescriptionUpdate = (value) => {
    onContractUpdate({
      ...props.contract,
      desctiption: value
    });
  };

  return (
    <div>
      <InlineFlex mr="xl">
        <TextInput name="variant" label="Variant" minWidth="25rem" value={props.contract.variantName} disabled/>
        <PrimaryButton type="button" size="medium" ml="m" mt="xs" onClick={() => onVariantClick()}>Change</PrimaryButton>
      </InlineFlex>
      <TextInput name="status" maxWidth="25rem" label="Status Code" width="8rem" value={props.contract.statusCode} type="number" onChange={(event) => onStatusUpdate(event.target.value)}/>
      <TextInput name="endpoint" label="Endpoint" value={props.contract.endpoint} onChange={(event) => onEndpointUpdate(event.target.value)}/>
      <Select name="method" maxWidth="25rem" label="Request Method" value={props.contract.method} onChange={(event) => onMethodUpdate(event.target.value)}>
        <option value="GET">GET</option>
        <option value="POST">POST</option>
        <option value="PUT">PUT</option>
        <option value="PATCH">PATCH</option>
        <option value="DELETE">DELETE</option>
      </Select>
      <TextInput name="category" label="Category" value={props.contract.category} onChange={(event) => onCategoryUpdate(event.target.value)}/>
      <TextInput name="description" label="Description" value={props.contract.description} onChange={(event) => onDescriptionUpdate(event.target.value)}/>
    </div>
  )
}

export default CreateContractDialog;
