import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { useAuthContext } from '../providers/AuthProvider';
import Contracts from '../pages/Contracts';
import EditContract from '../pages/EditContract';
import Deeplinks from '../pages/Deeplinks';
import Login from '../pages/Login';

export default function Routes() {
  const { token } = useAuthContext();

  if(!token) {
    return (<Login />)
  }

  return (
    <Switch>
      <Route path="/" exact component={ Contracts } />
      <Route path="/contract/:contractId" component={ EditContract } />
      <Route path="/deeplinks" component={ Deeplinks }  />
    </Switch>
  );
}